import React from "react";
import {Popover} from "antd";
import {useAuth} from "../../authentication";
import {useNavigate} from "react-router-dom";

const UserProfile = () => {
  const {authUser, userSignOut} = useAuth();
  const navigate = useNavigate();

  const onLogoutClick = () => {
    userSignOut(() => {
      navigate('/signin');
    });
  }

  const userMenuOptions = (
    <ul style={{listStyle: "none",  padding: "0 10px", marginBottom: 0}}>
      <li
          style={{cursor: "pointer", display: "flex"}}
          onClick={onLogoutClick}>
            <span>Logout</span>
      </li>
    </ul>
  );

  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <span style={{cursor: "pointer", display: "flex", alignItems: "center", height: 36}}>
          <span style={{paddingLeft: 2, marginRight: 10, fontSize: 14}}>{authUser?.name}</span>
          {/* <i className="icon icon-chevron-down"/> */}
        </span>
      </Popover>
    </div>
  )
};

export default UserProfile;
