import React, {lazy, Suspense} from "react";
// import { Route, Switch } from "react-router-dom";
import {Route, Routes} from "react-router-dom";

import PageNotFound from "../components/PageNotFound";
import CircularProgressLong from "../components/CircularProgressLong";


// const Home = lazy(() => import('src/routes/home'));
const Home = lazy(() => import('src/routes/home'));
const HomeDashboard = lazy(() => import('src/routes/home/dashboard'));
const Clinic = lazy(() => import('src/routes/clinic'));
const ClinicManage = lazy(() => import('src/routes/clinic/manage'));
const Subsidiary = lazy(() => import('src/routes/subsidiary'));
const SubsidiaryManage = lazy(() => import('src/routes/subsidiary/manage'));
const Post = lazy(() => import('src/routes/post'));
const PostTerms = lazy(() => import('src/routes/post/terms'));
const PostPrivacy = lazy(() => import('src/routes/post/privacy'));
const PostEmail = lazy(() => import('src/routes/post/email'));
const User = lazy(() => import('src/routes/user'));
const UserCs = lazy(() => import('src/routes/user/cs'));
const UserStaff = lazy(() => import('src/routes/user/staff'));

// const App = ({ match }) => {
const App = () => {
  // const matchAction = useMatch("/:gnb/:lnb/*");

  // const gnbName = matchAction.params.gnb;
  // const lnbName = matchAction.params.lnb;

  return <div style={{
    flex: "1",
    backgroundColor: "#F7F9FB",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  }}>
    <Suspense fallback={<CircularProgressLong messageId={"request.loading"}/>}>
      <Routes>
        {/*Home*/}
        <Route path="/home/*" element={<Home/>}/>
        <Route path="/home/dashboard/*" element={<HomeDashboard/>}/>

        {/*Clinic*/}
        <Route path="/clinic/*" element={<Clinic/>}/>
        <Route path="/clinic/manage/*" element={<ClinicManage/>}/>

        {/*Subsidiary*/}
        <Route path="/subsidiary/*" element={<Subsidiary/>}/>
        <Route path="/subsidiary/manage/*" element={<SubsidiaryManage/>}/>

        {/*Post*/}
        <Route path="/post/*" element={<Post/>}/>
        <Route path="/post/terms/*" element={<PostTerms/>}/>
        <Route path="/post/privacy/*" element={<PostPrivacy/>}/>
        <Route path="/post/email/*" element={<PostEmail/>}/>

        {/*User*/}
        <Route path="/user/*" element={<User/>}/>
        <Route path="/user/cs/*" element={<UserCs/>}/>
        <Route path="/user/staff/*" element={<UserStaff/>}/>

        <Route path="*" element={<PageNotFound/>}/>
        {/*<Route path="*" element={asyncComponent(() => import('./' + gnbName + '/' + lnbName))}/>*/}
      </Routes>
    </Suspense>
  </div>
}


export default App;
