import {Loading3QuartersOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import React from "react";
import CircularProgressShort from "../CircularProgressShort";
import IntlMessages from "../../util/IntlMessages";
import './index.css';
import CircularProgress from "../CircularProgress";


const CircularProgressLong = ({className, spin, messageId}) => {
  if (messageId) {
    return (<div className="gx-loader-view-fixed">
      <div className="content">
        <CircularProgressShort className={className} spin={spin}/>
        <IntlMessages id={messageId}/>
      </div>
    </div>)
  } else {
    return <CircularProgress/>
  }
}
export default CircularProgressLong;
