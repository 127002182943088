import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SEARCH_OPTIONS,
  SHOW_MESSAGE,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH
} from '../../constants/ActionTypes'
import {EMAIL_STATUS, TERMS_STATUS} from "../../util/common";

const INIT_STATE = {
  error: "",
  loading: false,
  message: '',
  navCollapsed: true,
  width: window.innerWidth,
  pathname: '/',
  searchOptions: {
    '/clinic/manage': {
      keyword: undefined,
      currentPage: 0,
      pageLength: 20,
      sortOrderBy: 2,
      sortColumnName: 'id',
      openSearchOption: false,
    },
    '/clinic/waiting': {
      keyword: undefined,
      currentPage: 0,
      pageLength: 20,
      sortOrderBy: 2,
      sortColumnName: 'id',
      openSearchOption: false,
    },
    '/user/cs': {
      keyword: undefined,
      currentPage: 0,
      pageLength: 20,
      sortOrderBy: 2,
      sortColumnName: 'id',
      openSearchOption: false,
    },
    '/user/staff': {
      keyword: undefined,
      currentPage: 0,
      pageLength: 20,
      sortOrderBy: 2,
      sortColumnName: 'id',
      openSearchOption: false,
    },
    '/subsidiary/manage': {
      keyword: undefined,
      currentPage: 0,
      pageLength: 20,
      sortOrderBy: 2,
      sortColumnName: 'id',
      openSearchOption: false,
    },
    '/post/terms': {
      keyword: undefined,
      category: undefined,
      currentPage: 0,
      pageLength: 20,
      sortOrderBy: 2,
      sortColumnName: 'id',
      status: TERMS_STATUS,
      enforcement_date: undefined,
      lang: undefined,
      openSearchOption: false,
    },
    '/post/privacy': {
      keyword: undefined,
      category: undefined,
      currentPage: 0,
      pageLength: 20,
      sortOrderBy: 2,
      sortColumnName: 'id',
      status: TERMS_STATUS,
      enforcement_date: undefined,
      lang: undefined,
      openSearchOption: false,
    },
    '/post/email': {
      keyword: undefined,
      category: undefined,
      sub_category: undefined,
      currentPage: 0,
      pageLength: 20,
      sortOrderBy: 2,
      sortColumnName: 'id',
      status: EMAIL_STATUS,
      lang: undefined,
      openSearchOption: false,
    },
  },
};

const CommonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false
      }
    }
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case TOGGLE_COLLAPSED_NAV: {
      return {
        ...state,
        navCollapsed: action.navCollapsed
      }
    }
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true};
    }
    case FETCH_SUCCESS: {
      return {...state, error: '', message: '', loading: false};
    }
    case SHOW_MESSAGE: {
      return {...state, error: '', message: action.payload, loading: false};
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, message: ''};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '', message: ''};
    }
    case SEARCH_OPTIONS: {
        return {
          ...state,
          searchOptions: action.payload
        }
    }
    default:
      return state;
  }
}

export default CommonReducer;
