import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { startProgress, successProgress, errorProgress } from "../appRedux/actions/Progress";
import Axios from 'axios';

import { useAuth } from 'src/authentication';
import { usePopup } from "src/util/use-popup";
import {useIntl} from "react-intl";

const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_HOST}`, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});

export const AxiosInterceptor = ({ children }) => {

  const { checkLogin, userSignOut } = useAuth();
  const { errorPopup, tpErrorPopup } = usePopup();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();


  useEffect(() => {

    const reqInterceptor = axios.interceptors.request.use(
      (conf) => {
        dispatch(startProgress());
        return conf;
      }
    );


    const resInterceptor = axios.interceptors.response.use(
      (response) => {
        if ((response.data.tpErrCode || response.data.tpErrType) && response.data.userErrCode !== "-17211") {
          tpErrorPopup(response.data, () => { });
          dispatch(errorProgress(response));

          // return response;
          return Promise.reject(response.data);
        } else if (response.data.userErrMsg === "License was not found.") {
          tpErrorPopup(response.data, () => {});
          dispatch(errorProgress(response));
          return Promise.reject(response.data);
        } else if (!response.data){
          checkLogin();
          dispatch(errorProgress(null));
          return Promise.reject(null);
        } else {
          dispatch(successProgress());
          return response;
        }

      },
      (error) => {
        dispatch(errorProgress(error));
        console.log("error : ", error.response)
        console.log("error : ", error.response.status)
        // TODO 특정 에러코드일 경우 강제 로그아웃. 일단은 401일 경우 무조건 로그아웃 하도록 처리.
        if (error.response.status === 401) {
          console.log("401 error : userSignOut")
          userSignOut();
        } else if (error.response.status === 403) {
          errorPopup(intl.formatMessage({ id: error.response.data.detail}), () => { });
        } else if (error.response.status === 404) {
          errorPopup("요청하신 페이지가 존재하지 않습니다.(" + error.request.responseURL + ")", () => { navigate(-1) });
        } else if (error.response.status === 400 || error.response.status === 500) {
          if (error.response.data?.message) {
            errorPopup(intl.formatMessage({ id: error.response.data.message}), () => { });
          } else {
            errorPopup(error.response.statusText + "(" + error.response.status + ")");
          }
        }
        else if (!error.response) {
          error.response = {
            data: {
              error: "알수 없는 에러가 발생하였습니다.",
              error_description: error.stack,
              message: "알수 없는 에러가 발생하였습니다. : " + error.stack
            }
          }
          errorPopup(error.response.data.message, () => { });
        } else {
          error.response.data = {
            ...error.response.data,
            // message: error.response.data.detail ? error.response.data.detail : error.response.data.error_description
          }
        }

        return Promise.reject(error);
      }
    )

    return () => {
      axios.interceptors.request.eject(reqInterceptor);
      axios.interceptors.response.eject(resInterceptor);
    }

  }, [])


  return children;
}

export default axios
