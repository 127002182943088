import React, { useState, useContext, createContext } from "react";
import IntlMessages from './IntlMessages';
import {notification} from "antd";

const notificationContext = createContext({});

export function NotificationProvider({ modal, children }) {
    const notification = useProviderNotification();
    return <notificationContext.Provider value={notification}>{children}</notificationContext.Provider>;
}

export const useNotification = () => {
    return useContext(notificationContext);
};


const useProviderNotification = () => {

    const [api, contextHolder] = notification.useNotification({maxCount: 5});

    const infoNotification = (message, description, onClick, key) => {
    //   console.log('infoNotification', message, description)
        api.info({
            message: `${message}`,
            description: description,
            placement: 'topRight',
            onClick: () => { onClick && onClick() },
            key
        });
    }

    const errorNotification = (message, description, onClick) => {
        api.error({
            message: `${message}`,
            description: description,
            placement: 'topRight',
            onClick: () => { onClick && onClick() },
        });
    }


    const destroyNotification = (key)  => {
        api.destroy(key);
    }

    return {
        infoNotification,
        errorNotification,
        destroyNotification,
        contextHolder,
    };
}
