import {useEffect, useState} from "react";
import {httpClient} from "util/Api";
import {Cookies} from "react-cookie";
import {useLocation} from "react-router-dom";


export const useProvideAuth = () => {
  const location = useLocation();
  const [authUser, setAuthUser] = useState(null);
  const [firstLoginUser, setFirstLoginUser] = useState(null);
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [gnb, setGnb] = useState(null);
  const [lnb, setLnb] = useState(null);

  const fetchStart = () => {
    setLoading(true);
    setError('');
  }

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  }

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  }

  const userLogin = (data, callbackFun) => {
    fetchStart();
    setFirstLoginUser(null);
    httpClient.post('oauth/token/', data, {
      baseURL: process.env.REACT_APP_HOST,
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
      .then(({data}) => {
        if (data.access_token) {
          fetchSuccess();
          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;

          const access_info = {
            access_token: data.access_token,
          }
          localStorage.setItem('access_info', JSON.stringify(access_info));

          getAuthUser();

          if (callbackFun) callbackFun();
        } else if (data.is_first) {
          fetchSuccess();
          // 첫 로그인
          setFirstLoginUser(data)
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const userSignOut = (callbackFun) => {
    const fcmToken = localStorage.getItem('fcm_token');

    const formData = new FormData();
    formData.append("fcm_token", fcmToken);

    fetchStart();
    httpClient.post(
      `oauth/logout/`,
      formData, {
        baseURL: process.env.REACT_APP_HOST,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    )
      .then(({data}) => {
        if (data.result) {
          fetchSuccess();
          setAuthUser(undefined);
          httpClient.defaults.headers.common['Authorization'] = '';
          localStorage.removeItem('fcm_token')
          localStorage.removeItem('access_info')
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };
  const getAuthUser = () => {
    // fetchStart();
    setLoadingUser(true)
    httpClient.get("v1/bo/profile/", {baseURL: process.env.REACT_APP_HOST}).then(({data}) => {
      if (data.username) {
        setAuthUser(data)
        // console.log('permission menus', data.menus)
      }
    }).catch((error) => {
      setAuthUser(undefined)
      httpClient.defaults.headers.common['Authorization'] = '';
    }).finally(() => {
      setLoadingUser(false)
    })
  }
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {

      const onRefresh = async () => {
        try {
          const access_info = localStorage.getItem('access_info');
          if (access_info) {
            const ai = JSON.parse(access_info)
            if (ai.access_token.length > 0) {
              httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + ai.access_token;
              getAuthUser()
            }
          } else {
            setLoadingUser(false)
          }
        } catch (e) {

        } finally {
          // setLoadingUser(false);

        }
      }

      onRefresh();
      // invalidateAuthUser()
      // httpClient.get("v1/bm/profile/").then(({data}) => {
      //   if (data.username) {
      //     setAuthUser(data);
      //   }
      //   setLoadingUser(false);
      // }).catch(function (error) {
      //   cookies.remove('token');
      //   httpClient.defaults.headers.common['Authorization'] = '';
      //   setLoadingUser(false);
      // });
    }, []
  );

  useEffect(() => {
    if (authUser) {
      setLoadingUser(false)
    }
  }, [authUser]);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    firstLoginUser,
    gnb,
    lnb,
    error,
    setAuthUser,
    getAuthUser,
    userLogin,
    setLnb,
    userSignOut,
    setFirstLoginUser,
  };
}

export const isUnRestrictedRoute = (pathname) => {
  return (pathname === '/signin' ||
    pathname === '/signup' ||
    pathname === '/forgot-password' ||
    pathname === '/reset-password' ||
    pathname === '/terms' ||
    pathname === '/404');
}

export const isRestrictedRoute = (pathname) => {
  return (
    pathname === '/' ||
    pathname === '/select-user' ||
    pathname === '/pin');
}
