import { Modal } from "antd";

import AtomicTitle from "../AtomicTitle";

const AtomicPopup = (props) =>  {
    return (
        <Modal {...props} 
            bodyStyle={{backgroundColor: "#F7F9FB"}}
            maskClosable={false}
            title={<AtomicTitle level={3}>{props.title}</AtomicTitle>}
            >
            <div style={{padding: "35px 25px"}}>
            {props.children}
            </div>

        </Modal>
    )
}

export default AtomicPopup;