import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {ConfigProvider} from 'antd';
import {IntlProvider} from "react-intl";

import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "src/routes/userAuth/SignIn";
import NotFound from "../NotFound";
import {setInitUrl, updateWindowWidth} from "../../appRedux/actions";
import {PopupProvider, usePopup} from "../../util/use-popup";
import {AxiosInterceptor} from "src/util/Axios";
import {NotificationProvider} from "src/util/useNotification";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import CircularProgress from "../../components/CircularProgress";
import {useAuth} from "../../authentication";
import CircularProgressLong from "../../components/CircularProgressLong";

// const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
//   <Route
//     {...rest}
//     render={props =>
//       // authUser
//       //   ?
//       <Component {...props} />
//       // : <Redirect
//       //   to={{
//       //     pathname: '/signin',
//       //     state: {from: location}
//       //   }}
//       // />
//     }
//   />;

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('full-layout');
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove('full-layout');
    document.body.classList.remove('framed-layout');
    document.body.classList.add('boxed-layout');
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove('boxed-layout');
    document.body.classList.remove('full-layout');
    document.body.classList.add('framed-layout');
  }
};

const setNavStyle = (navStyle) => {
  if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER) {
    document.body.classList.add('full-scroll');
    document.body.classList.add('horizontal-layout');
  } else {
    document.body.classList.remove('full-scroll');
    document.body.classList.remove('horizontal-layout');
  }
};

const App = () => {

  const locale = useSelector(({settings}) => settings.locale);
  const navStyle = useSelector(({settings}) => settings.navStyle);
  const layoutType = useSelector(({settings}) => settings.layoutType);
  const themeType = useSelector(({settings}) => settings.themeType);
  const isDirectionRTL = useSelector(({settings}) => settings.isDirectionRTL);
  const initURL = useSelector(({settings}) => settings.initURL);
  const {width} = useSelector(({settings}) => settings);

  const {firstLoginUser, authUser, isLoadingUser} = useAuth();
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  // console.log('4 > ', width)
  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth));
    })
  }, []);

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add('rtl');
      document.documentElement.setAttribute('data-direction', 'rtl')
    } else {
      document.documentElement.classList.remove('rtl');
      document.documentElement.setAttribute('data-direction', 'ltr')
    }
  }, [isDirectionRTL]);

  useEffect(() => {
    if (locale)
      document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
    }
  }, [themeType]);

  useEffect(() => {
    // console.log("App/index.js[", initURL, "]")
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
  });

  useEffect(() => {
    // console.log("App.js initURL", initURL, isLoadingUser, authUser)

    if (!isLoadingUser) {
      if (!authUser) {
        navigate('/signin');
        // navigate('/home');
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        dispatch(setInitUrl('/home'));
        navigate('/home');
      } else {
        dispatch(setInitUrl(location.pathname));
        navigate(location.pathname)
      }
    }
  }, [isLoadingUser, authUser, initURL]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];

  return isLoadingUser && initURL.length === 1 ? <CircularProgressLong /> : (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={isDirectionRTL ? 'rtl' : 'ltr'}
      theme={{
        token: {
          fontSize: 14,
          fontFamily: 'Pretendard',
          colorPrimary: '#DF043A',
        },
        components: {
          Button: {
            fontSize: 13
          }
        }
      }}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <PopupProvider>
          <NotificationProvider>
            <AxiosInterceptor>
              <RouteApp />
            </AxiosInterceptor>
          </NotificationProvider>
        </PopupProvider>
      </IntlProvider>
    </ConfigProvider>
  )
};


const RouteApp = () => {
  const { contextHolder } = usePopup();

  return <>
    {contextHolder}
    <Routes>
      <Route path='/signin' element={<SignIn/>}/>
      <Route path="/*" element={<MainApp/>}/>
      <Route path="*" element={<NotFound/>}/>
    </Routes>
  </>
}

export default App;
