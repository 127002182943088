import React from "react";
import {Link} from "react-router-dom";
import IconButton from "../../components/IconButton";
import BO from "../../assets/svg/BO";


const SidebarLogo = ({sidebarCollapsed, setSidebarCollapsed}) => {

  return (
    <div style={{
      padding: "10px 30px 10px 15px",
      paddingLeft: sidebarCollapsed ? 0 : "15px",
      height: 64,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "nowrap"
    }}>
      <div style={{fontSize: 20}}>
        <IconButton className={`icon icon-${!sidebarCollapsed ? 'menu-fold' : 'menu-unfold'}`} color="#000"
                    onClick={() => {
                      setSidebarCollapsed(!sidebarCollapsed)
                    }}
        />
      </div>

      {/* TODO: Always home/dashboard is logo's location */}
      <Link to="/home/dashboard" style={{display: sidebarCollapsed ? "none" : "block", paddingLeft: 1}}>

        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          // justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div className="gx-app-logo" style={{
            display: 'flex',
            justifyContent: 'center',
            transform: "scale(1.0)",
          }}>
            <img alt="logo" src={(`${process.env.PUBLIC_URL}/assets/images/logo.png`)}
                 style={{width: 110, height: 40}}/>
          </div>
          {/*<div className="gx-app-logo" style={{*/}
          {/*  flex: 0,*/}
          {/*  display: 'flex',*/}
          {/*  justifyContent: 'center',*/}
          {/*  transform: "scale(0.5)",*/}
          {/*}}>*/}
          {/*  /!*<img alt="example" src="/assets/images/logo.png" height={40}/>*!/*/}
          {/*  <BO/>*/}
          {/*</div>*/}
        </div>
      </Link>
    </div>
  );
};

export default SidebarLogo;
