import React from "react";


const NotFound = () => {

  return (
    <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <h1>page not found</h1>
    </div>
  );
};

export default NotFound;
