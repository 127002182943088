import antdEN from "antd/locale/en_US";
import enMessages from "../locales/en_US.json";

const EnLang = {
  messages: {
    ...enMessages
  },
  antd: antdEN,
  locale: 'en-US',
};
export default EnLang;
