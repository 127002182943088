import React, {useEffect, useState} from "react";
import {Menu} from "antd";
import {Link, useLocation} from "react-router-dom";
import {ClusterOutlined, ContactsOutlined, DashboardOutlined, ProfileOutlined, TeamOutlined} from "@ant-design/icons";

import {useAuth} from "../../authentication";

import SidebarLogo from "./SidebarLogo";
import "./SidebarContent.css"
import IntlMessages from "../../util/IntlMessages";

const MENU_ICONS = {
  "bo.menus.home": <DashboardOutlined style={{color: '#000'}}/>,
  "bo.menus.clinic": <ContactsOutlined style={{color: '#000'}}/>,
  "bo.menus.subsidiary": <ClusterOutlined style={{color: '#000'}}/>,
  "bo.menus.post": <ProfileOutlined style={{color: '#000'}}/>,
  "bo.menus.user": <TeamOutlined style={{color: '#000'}}/>,
}

const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

  const {authUser} = useAuth();
  const [items, setItems] = useState([]);
  const location = useLocation();

  const selectedKeys = location.pathname.substring(1).split('/').slice(0, 2).join('/');
  const defaultOpenKeys = selectedKeys.split('/')[0];


  useEffect(() => {
    if (authUser) {

      setItems(authUser.menus.map((item) => {
        let childNodes = null;
        let linkId = item.id;
        if (item.children) {
          childNodes = item.children.map((child) => {
            return getItem(makeLabelItem(child.id, null, <IntlMessages id={child.text}/>), child.id, null, null)
          })
          linkId = item.children[0].id;

        }
        return getItem(makeLabelItem(linkId, MENU_ICONS[item.text], <IntlMessages
          id={item.text}/>), item.id, null, childNodes)
      }));
    }
  }, [authUser]);


  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type
    };
  }

  const makeLabelItem = (link, icon, label) => {
    return <Link to={link} style={{display: "flex", height: "50px", alignItems: "center"}}>
      {icon}
      {/* <i className={"icon icon-"+icon} style={{marginRight: 12, fontSize: 20, height: "37px", color: "#000"}} /> */}
      <span style={{lineHeight: "40px", color: "#000", fontWeight: 400, height: "40px", fontSize: "14px"}}>
                {/* <IntlMessages id={label} /> */}
        {label}
              </span>
    </Link>
  }

  // console.log('selectedKeys', selectedKeys, defaultOpenKeys, items)
  // console.log('selectedKeys', selectedKeys)

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
      <div style={{}}>
        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          mode="inline"
          theme="light"
          items={items}/>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

