export const formItemLayoutDefault = {
    labelCol: {
      xs: { span: 4 }, //  - 575
      sm: { span: 4 }, // 576 - 768
      md: { span: 4 }, // 769 - 991
      lg: { span: 4 }, // 992 - 1199
      xl: { span: 4 }, // 1200 - 1599
      xxl: { span: 4 }, // 1600 -
    },
    wrapperCol: {
      xs: { span: 20 },
      sm: { span: 20 },
      md: { span: 20 }, // 769 - 991
      lg: { span: 20 }, // 992 - 1199
      xl: { span: 20 }, // 1200 - 1599
      xxl: { span: 20 }, // 1600 -
    },
}

export const formItemLayoutVerticalDefault = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 }, // 769 - 991
    lg: { span: 12 }, // 992 - 1199
    xl: { span: 12 }, // 1200 - 1599
    xxl: { span: 12 }, // 1600 -
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 }, // 769 - 991
    lg: { span: 12 }, // 992 - 1199
    xl: { span: 12 }, // 1200 - 1599
    xxl: { span: 12 }, // 1600 -
  },
}

export const formItemLayoutSplit = {
  labelCol: {
      span: 8
  },
  wrapperCol: {
      span: 16
  },
}
