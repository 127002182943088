import React, {useState} from "react";
import {Button, Col, Form, Input, Row,} from "antd";
// import authApis from "util/auth/authApis";
import {usePopup} from "src/util/use-popup";
import AtomicPopup from "src/components/AtomicPopup";
import {checkPassword, getLoginFormData} from "src/util/common";
import {useAuth} from "src/authentication";
import {httpClient} from "src/util/Api";
import {useIntl} from "react-intl";
import AtomicForm from "../AtomicForm";
import { Buffer } from 'buffer';

//import CardTable from "./cardTable";

const ChangePasswordPopup = ({handleConfirm, handleClose, msg1, userid, isFirst = false}) => {
  const [form] = Form.useForm();
  const {firstLoginUser, userSignOut} = useAuth();
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState("");
  const intl = useIntl();

  const {infoPopup} = usePopup();

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  const initFormValue = {
    password: "",
    new_password: "",
    re_new_password: "",
  }


  const handleChange = async (name) => {


    const data = Object.assign({}, form.getFieldsValue(true));
    const {password, new_password, re_new_password} = data;

    setError("");

    try {
      //password를 변경할때 새 비밀번호가 입력된 상태이면 validation check 같이 하기 위해서.
      // const list = name === "password" && form.getFieldValue("new_password")?.length? ['password', 'new_password']: [name];

      const list = ["password", "new_password", "re_new_password"].map((i) => form.getFieldValue(i).length? i: "").filter(Boolean);

      if (!list.length) {
        console.log("list.length is 0", list.length)
        return;
      }
      else {
        console.log("list.length", list.length)

        const values = await form.validateFields(list);
        console.log("handleChange validate success", values);
      }


      if (password.length && new_password.length && re_new_password.length)
        setIsReady(true);
      else
        setIsReady(false);


    } catch (errorInfo) {
      console.log("handleChange validate failed", errorInfo);
      setIsReady(false);
    }

  }

  const onFinish = values => {
    const {password, new_password, re_new_password} = values;

    console.log('isFirst', Buffer.from(password).toString('Base64'))

    if (isFirst) {
      const data = {
        id: firstLoginUser?.id,
        password: Buffer.from(password).toString('Base64'),
        new_password: Buffer.from(new_password).toString('Base64'),
        // re_new_password: Buffer.from(re_new_password).toString('Base64'),
        uuid: firstLoginUser?.uuid,
      };

      console.log('isFirst', data)

      httpClient.patch(`/v1/bo/users/staff/first_login/`, data)
        .then((resp) => {
          if (resp.data) {
            console.log("ChangePassword result", resp.data);

            infoPopup(intl.formatMessage({id: `user.info.pw_changed`}), () => {
              handleClose();
            });

          } else {
            console.log("ChangePassword result", resp);

          }
        })
        .catch(error => {
          // console.log("catch ChangePassword error", error);
          if (error.response.data?.message) {
            setError(intl.formatMessage({id: error.response.data?.message}))
          }
        })
    } else {

      // TODO: FIXME

      const formData = getLoginFormData({email: userid, password: password});
      // formData.append("username", userid);
      // formData.append("password", Buffer.from(password).toString('Base64'));
      formData.append("new_password", Buffer.from(new_password).toString('Base64'));

      // const data = {
      //   password: Buffer.from(password).toString('Base64'),
      //   new_password: Buffer.from(new_password).toString('Base64'),
      //   re_new_password: Buffer.from(re_new_password).toString('Base64')
      // };
      httpClient.post("/v1/change_password/", formData, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
        .then((resp) => {
          if (resp.data) {
            console.log("ChangePassword result", resp.data);
            infoPopup(intl.formatMessage({id: `user.info.pw_changed`}), () => {
              handleClose();
              userSignOut();
            });
          } else {
            console.log("ChangePassword result", resp);

          }
        })
        .catch(error => {
          // console.log("catch ChangePassword error", error);

          if (error.response.data?.message) {
            setError(intl.formatMessage({id: error.response.data?.message}))
          }

          // if (error.response.data) {
          //   if (error.response.data.error === "invalid_grant" && error.response.data.error_description === "Invalid credentials given.")
          //     setError(intl.formatMessage({id: `user.info.not_match_current_pw`}))
          //   else
          //     setError(error.response.data);
          // }
        })

    }


  };


  return (
    <AtomicPopup
      title={intl.formatMessage({id: `user.change_password`})}
      centered={true} visible={true} width={800} mask
      onCancel={handleClose}
      footer={null}
    >
      <div>
        <div>
          <div style={{marginBottom: 20, color: "#000000", fontSize: 24}}>
            {intl.formatMessage({id: `user.password_change_notification`})}
          </div>
          <div style={{marginBottom: 30, color: "#545454", fontSize: 14}}>{msg1}</div>
          {error && <div style={{color: "#ee3224", marginBottom: 10}}>{error}</div>}
          <AtomicForm
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={initFormValue}
            layout="vertical"
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <Form.Item name="password"
                       label={`${intl.formatMessage({id: `common.current_password`})}`}
                       required rules={[{required: true}]}>
              <Input.Password type="password"
                              style={{maxWidth: 300}}
                              placeholder={`${intl.formatMessage({id: `user.input_password`})}`}
                              autoFocus
                              onChange={() => {
                                handleChange('password')
                              }}

              />
            </Form.Item>
            <Form.Item name="new_password" label={`${intl.formatMessage({id: `common.new_password`})}`} required
                       rules={[
                         {required: true, message: intl.formatMessage({id: `user.input_new_password`})},
                         {
                           validator: (_, value) => {
                             const pwd = form.getFieldValue("password");

                             const check = checkPassword(value, isFirst ? firstLoginUser.account : "");

                             if (pwd === value && value.length) {
                               return Promise.reject(setError(intl.formatMessage({id: `user.warn.cant_use_current_password`})));
                             } else if (!check.result) {
                               return Promise.reject(setError(check.msg))
                             } else {
                               return Promise.resolve();
                             }
                           }
                         }
                       ]}>
              <Input.Password type="password"
                              style={{maxWidth: 300}}
                              onChange={() => handleChange('new_password')}
                              placeholder={intl.formatMessage({id: `user.input_new_password`})}/>
            </Form.Item>
            <Form.Item name="re_new_password" label="신규비밀번호 확인" className="last" required
                       dependencies={['new_password']}
                       rules={[
                         {required: true, message: intl.formatMessage({id: `user.input_new_password`})},
                         {
                           validator: (_, value) => {
                             const check = checkPassword(value, isFirst ? firstLoginUser.account : "");
                             if (form.getFieldValue("new_password") !== value && value.length) {
                               return Promise.reject(setError(intl.formatMessage({id: `user.warn.not_matched_new_password`})));
                             } else if (!check.result) {
                               return Promise.reject(setError(check.msg))
                             } else {
                               return Promise.resolve();
                             }
                           }
                         }
                       ]}>
              <Input.Password type="password"
                              style={{maxWidth: 300}}
                              onChange={() => handleChange('re_new_password')}
                              placeholder={intl.formatMessage({id: `user.input_new_password`})}/>
            </Form.Item>
            <Col span={24} style={{color: "#bfbfbf", fontSize: 13}} className="gx-mt-3">
              <Col>
                <span>{intl.formatMessage({id: `user.change_pw.message1`})}</span>
              </Col>
              <Col>
                <span>{intl.formatMessage({id: `user.change_pw.message2`})}</span>
              </Col>
              <Col>
                <span>{intl.formatMessage({id: `user.change_pw.message3`})}</span>
              </Col>
              <Col>
                <span>{intl.formatMessage({id: `user.change_pw.message4`})}</span>
              </Col>
            </Col>
            <Row className="button-row">
              <Col span={24} style={{textAlign: "right"}}>
                <Button type="primary" htmlType="submit" disabled={!isReady}>{intl.formatMessage({id: `button.save`})}</Button>
              </Col>
            </Row>
          </AtomicForm>

        </div>
      </div>
    </AtomicPopup>
  );
};

const mapStateToProps = ({settings}) => {
  const {navStyle} = settings;
  return {navStyle};
};

export default ChangePasswordPopup;
