import React, { useEffect, useState } from "react";
import  {Modal, Spin, Typography, Table} from "antd";
import { connect, useSelector } from "react-redux";
import CircularProgressLong from "../CircularProgressLong";

const Progress = () => {

  const {count} = useSelector(state => state.progress)
  // console.log("count : ", count);
  return (
    <div>
      {count > 0 &&  <CircularProgressLong messageId={'request.loading'}/>}
    </div>
  );
};

export default Progress;

// const mapStateToProps = ({request}) => {
//   const {loading} = request;

//   return {loading};
// };
// export default connect(mapStateToProps)(Progress);
