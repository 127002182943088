import { Typography } from "antd";
const { Title } = Typography;

const AtomicTitle = (props) =>  {
    return (
        <Title {...props} style={{...props.style, marginBottom: 0, marginTop: 0}}>
            {props.children}
        </Title>
    )
}

export default AtomicTitle;