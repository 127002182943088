import React, {useEffect} from "react";
import {Provider} from 'react-redux'
// import {ConnectedRouter} from 'connected-react-router'
import {BrowserRouter as Router} from "react-router-dom";
import 'antd/dist/reset.css';
import "assets/vendors/style";
import "assets/fonts/SpoqaHanSansNeo.css";

import configureStore from './appRedux/store';
import App from "./containers/App/index";
import {AuthProvider} from "./authentication";

const store = configureStore();

const NextApp = () => {

  return <Provider store={store}>
    <Router>
      <AuthProvider>
        <App/>
      </AuthProvider>
    </Router>
  </Provider>;
}


export default NextApp;
