import React, {createContext, useContext} from "react";
import {Descriptions, Modal} from "antd";
import IntlMessages from './IntlMessages';

const popupContext = createContext({});

var infoMessageCount = 0;
var warningMessageCount = 0;
var errorMessageCount = 0;


export function PopupProvider({modal, children}) {
  const popup = useProvidePopup();
  return <popupContext.Provider value={popup}>{children}</popupContext.Provider>;
}


export const usePopup = () => {
  return useContext(popupContext);
};


const useProvidePopup = () => {
  const [api, contextHolder] = Modal.useModal();


  const confirmPopup = (content, onOk, onCancel, cancelText = <IntlMessages id="button.cancel"/>) => {
    api.confirm({
      title: <IntlMessages id="popup.confirm"/>,
      centered: true,
      content: <div style={{fontSize: 14}}>{content}</div>,
      okText: <IntlMessages id="button.ok"/>,
      cancelText: cancelText,
      onOk: () => {
        onOk && onOk();
      },
      onCancel: () => {
        onCancel && onCancel();
      }
    });
  }

  const infoPopup = (content, onOk) => {
    if (infoMessageCount === 1) {
      return;
    }
    infoMessageCount = 1;

    api.info({
      title: <IntlMessages id="popup.info"/>,
      centered: true,
      content: <div style={{maxHeight: 556, overflowY: "auto"}}>{content}</div>,
      okText: <IntlMessages id="button.ok"/>,
      onOk: () => {
        onOk && onOk();
        infoMessageCount = 0;
      },
      onCancel: () => {
        infoMessageCount = 0;
      }

    });
  }

  const warningPopup = (content, onOk) => {
    if (warningMessageCount === 1) {
      return;
    }
    warningMessageCount = 1;

    api.warning({
      title: <IntlMessages id="popup.warning"/>,
      centered: true,
      content: content,
      okText: <IntlMessages id="button.ok"/>,
      onOk: () => {
        onOk && onOk();
        warningMessageCount = 0;
      },
      onCancel: () => {
        warningMessageCount = 0;
      }
    });
  }

  const errorPopup = (content, onOk) => {
    if (errorMessageCount === 1) {
      return;
    }
    errorMessageCount = 1;

    console.log('api', content)

    api.error({
      title: <IntlMessages id="popup.error"/>,
      centered: true,
      content: content,
      okText: <IntlMessages id="button.ok"/>,
      onOk: () => {
        onOk && onOk();
        errorMessageCount = 0;
      },
      onCancel: () => {
        errorMessageCount = 0;
      }
    });
  }

  const tpErrorPopup = (data, onOk) => {
    if (errorMessageCount === 1) {
      return;
    }
    errorMessageCount = 1;

    const items = [
      {key: "userErrCode", label: <IntlMessages id="popup.userErrCode"/>, children: data.userErrCode},
      {key: "userErrType", label: <IntlMessages id="popup.userErrType"/>, children: data.userErrType},
      {key: "userErrMsg", label: <IntlMessages id="popup.userErrMsg"/>, children: data.userErrMsg},
      {key: "errComment", label: <IntlMessages id="popup.errComment"/>, children: data.errComment},
      {key: "tpErrType", label: <IntlMessages id="popup.tpErrType"/>, children: data.tpErrType},
      {key: "tpErrCode", label: <IntlMessages id="popup.tpErrCode"/>, children: data.tpErrCode},
      {key: "tpErrMsg", label: <IntlMessages id="popup.tpErrMsg"/>, children: data.tpErrMsg},
    ]

    const content = <Descriptions column={1} bordered size="middle">
      {items.map((item) => (<Descriptions.Item key={item.key} label={item.label}>{item.children}</Descriptions.Item>))}
    </Descriptions>

    api.error({
      title: <IntlMessages id="popup.error"/>,
      centered: true,
      content: content,
      width: 600,
      // content: <Descriptions items={items} column={1}/>,
      okText: <IntlMessages id="button.ok"/>,
      onOk: () => {
        errorMessageCount = 0;
        onOk && onOk();
      },
      onCancel: () => {
        errorMessageCount = 0;
      }
    });
  }
  const tpSuccessPopup = (title = '', items, onOk) => {

    // const items = [
    //   {key: "userErrCode", label: <IntlMessages id="popup.userErrCode"/>, children: data.userErrCode},
    //   {key: "userErrType", label: <IntlMessages id="popup.userErrType"/>, children: data.userErrType},
    //   {key: "userErrMsg", label: <IntlMessages id="popup.userErrMsg"/>, children: data.userErrMsg},
    //   {key: "errComment", label: <IntlMessages id="popup.errComment"/>, children: data.errComment},
    //   {key: "tpErrType", label: <IntlMessages id="popup.tpErrType"/>, children: data.tpErrType},
    //   {key: "tpErrCode", label: <IntlMessages id="popup.tpErrCode"/>, children: data.tpErrCode},
    //   {key: "tpErrMsg", label: <IntlMessages id="popup.tpErrMsg"/>, children: data.tpErrMsg},
    // ]

    const content = <Descriptions column={1} bordered size="middle">
      {items.map((item) => (<Descriptions.Item key={item.key} label={item.label}>{item.children}</Descriptions.Item>))}
    </Descriptions>

    api.info({
      title: title,
      centered: true,
      content: content,
      width: 600,
      // content: <Descriptions items={items} column={1}/>,
      okText: <IntlMessages id="button.ok"/>,
      onOk: () => {
        onOk && onOk();
      }
    });
  }


  return {
    confirmPopup,
    infoPopup,
    warningPopup,
    errorPopup,
    tpErrorPopup,
    tpSuccessPopup,
    contextHolder

  };
}
