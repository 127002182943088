import antdKR from "antd/locale/ko_KR";
import enMessages from "../locales/ko_KR.json";

const EnLang = {
  messages: {
    ...enMessages
  },
  antd: antdKR,
  locale: 'ko-KR',
};
export default EnLang;
