import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={938}
    height={137}
    viewBox="0 0 938 137"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_220_13)">
      <path
        d="M619.047 135.005V10.4408H638.78V118.213H694.641V135.005H619.047Z"
        fill="url(#paint0_linear_220_13)"
      />
      <path
        d="M717.494 22.3269C709.985 22.3269 706.14 18.8375 706.14 12.0041C706.14 5.17068 709.804 1.68127 717.494 1.68127C725.184 1.68127 729.029 5.17068 729.029 12.0041C729.029 18.8375 725.003 22.3269 717.494 22.3269ZM708.063 135.006V38.938H726.925V135.006H708.063Z"
        fill="url(#paint1_linear_220_13)"
      />
      <path
        d="M813.691 135.006V72.8868C813.691 60.274 806.872 53.477 790.984 53.477C784.165 53.477 776.656 54.3493 770.381 55.4034V135.006H751.518V42.1C765.157 38.7924 780.138 37.5565 792.544 37.5565C819.604 37.5565 832.699 49.9875 832.699 72.5597V135.006H813.655H813.691Z"
        fill="url(#paint2_linear_220_13)"
      />
      <path
        d="M916.165 135.006C906.552 119.085 895.053 103.492 881.922 91.7878L874.414 96.1495V135.006H855.733V0.772339H874.414V78.6298C890.991 68.1252 904.122 55.1853 915.802 38.9014H937.457C925.414 56.5665 912.828 70.2334 897.302 82.1192C912.32 96.2949 926.793 115.014 938.001 134.969H916.165V135.006Z"
        fill="url(#paint3_linear_220_13)"
      />
      <path
        d="M278.654 71.1428C278.654 44.8995 298.387 37.5209 325.629 37.5209C348.88 37.5209 364.079 44.8632 364.079 67.3989C364.079 83.6829 356.715 91.2069 337.526 95.2052C327.406 97.1316 315.327 98.7309 302.595 99.5669V102.402C302.595 112.18 309.088 116.76 323.924 116.76C335.459 116.76 346.631 115.524 358.674 112.543V132.498C347.683 135.079 335.821 136.315 323.597 136.315C292.329 136.315 278.727 125.12 278.727 100.294V71.1064L278.654 71.1428ZM302.413 81.611C312.207 80.775 323.053 79.3574 330.925 77.431C337.164 75.868 340.138 73.4327 340.138 67.4716C340.138 60.4928 335.422 56.9671 324.758 56.9671C308.834 56.9671 302.377 60.2747 302.377 71.5063V81.611H302.413Z"
        fill="url(#paint4_linear_220_13)"
      />
      <path
        d="M455.924 132.171C444.389 135.188 433.035 136.388 421.863 136.388C391.973 136.388 378.878 123.811 378.878 100.149V73.76C378.878 50.1338 391.973 37.5574 422.008 37.5574C433.361 37.5574 444.534 38.7569 455.887 41.7374V61.0018C446.456 58.5302 435.248 56.9672 424.257 56.9672C410.11 56.9672 402.928 61.329 402.928 73.5783V100.403C402.928 112.144 409.566 116.869 423.894 116.869C434.885 116.869 446.057 115.451 455.851 112.834V132.171H455.924Z"
        fill="url(#paint5_linear_220_13)"
      />
      <path
        d="M187.353 102.802C187.353 129.045 167.657 136.387 140.415 136.387C117.2 136.387 102.183 129.045 102.183 106.473C102.183 90.1892 109.329 82.7015 128.554 78.6668C138.855 76.7404 150.898 75.1411 163.485 74.2687V71.2882C163.485 61.4742 157.028 56.9671 142.338 56.9671C130.657 56.9671 119.449 58.2029 107.406 61.1471V41.3738C118.578 38.7567 130.476 37.5209 142.483 37.5209C173.751 37.5209 187.353 48.7161 187.353 73.5418V102.766V102.802ZM163.63 92.1883C153.836 93.097 143.027 94.4419 135.192 96.3683C129.061 97.9676 125.906 100.367 125.906 106.364C125.906 113.343 130.621 116.832 141.286 116.832C157.173 116.832 163.666 113.488 163.666 102.293V92.1519L163.63 92.1883Z"
        fill="url(#paint6_linear_220_13)"
      />
      <path
        d="M229.068 59.0391V102.111C229.068 111.38 234.327 115.924 245.318 115.924C253.008 115.924 258.776 115.597 265.595 114.361V134.679C257.724 135.515 250.578 136.242 243.541 136.242C217.714 136.242 205.635 125.192 205.635 103.856V24.5812L229.032 21.9641V38.9023H265.559V59.0391H229.032H229.068Z"
        fill="url(#paint7_linear_220_13)"
      />
      <path
        d="M532.57 135.006V73.2875C532.57 62.7829 526.621 56.8218 513.526 56.8218C507.94 56.8218 502.172 57.3307 496.731 58.3848V134.97H473.008V0.773193H496.731V39.6292C502.862 38.3934 509.318 37.4847 518.06 37.4847C544.975 37.4847 556.837 49.4068 556.837 71.8336V135.006H532.57Z"
        fill="url(#paint8_linear_220_13)"
      />
      <path
        d="M50.8919 136.242H45.8499C35.5482 136.242 28.9101 132.898 24.92 124.175C15.6702 104.801 5.73124 66.0177 0 38.9021H25.6092C30.8326 65.2908 39.3569 100.112 44.7979 113.016C45.6685 114.615 46.3577 114.76 47.591 114.76H49.1871C50.3841 114.76 51.0733 114.615 51.9801 113.016C57.3849 100.112 65.9092 65.2908 71.1326 38.9021H96.7781C91.0468 66.0177 81.0716 104.801 71.8581 124.175C67.8317 132.934 61.1936 136.242 50.9282 136.242H50.8919Z"
        fill="url(#paint9_linear_220_13)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_220_13"
        x1={656.844}
        y1={10.4408}
        x2={656.844}
        y2={135.005}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2B60" />
        <stop offset={1} stopColor="#DF043A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_220_13"
        x1={717.585}
        y1={1.68127}
        x2={717.585}
        y2={135.006}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2B60" />
        <stop offset={1} stopColor="#DF043A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_220_13"
        x1={792.109}
        y1={37.5565}
        x2={792.109}
        y2={135.006}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2B60" />
        <stop offset={1} stopColor="#DF043A" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_220_13"
        x1={896.867}
        y1={0.772339}
        x2={896.867}
        y2={135.006}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2B60" />
        <stop offset={1} stopColor="#DF043A" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_220_13"
        x1={321.366}
        y1={37.5209}
        x2={321.366}
        y2={136.315}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2B60" />
        <stop offset={1} stopColor="#DF043A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_220_13"
        x1={417.401}
        y1={37.5574}
        x2={417.401}
        y2={136.388}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2B60" />
        <stop offset={1} stopColor="#DF043A" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_220_13"
        x1={144.768}
        y1={37.5209}
        x2={144.768}
        y2={136.387}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2B60" />
        <stop offset={1} stopColor="#DF043A" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_220_13"
        x1={235.615}
        y1={21.9641}
        x2={235.615}
        y2={136.242}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2B60" />
        <stop offset={1} stopColor="#DF043A" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_220_13"
        x1={514.923}
        y1={0.773193}
        x2={514.923}
        y2={135.006}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2B60" />
        <stop offset={1} stopColor="#DF043A" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_220_13"
        x1={48.389}
        y1={38.9021}
        x2={48.389}
        y2={136.242}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2B60" />
        <stop offset={1} stopColor="#DF043A" />
      </linearGradient>
      <clipPath id="clip0_220_13">
        <rect
          width={938}
          height={135.614}
          fill="white"
          transform="translate(0 0.773438)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
