import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, Input, Row, Typography} from "antd";
import './index.css'
import IntlMessages from "util/IntlMessages";
import {useAuth} from "src/authentication";
import {getLoginFormData} from "util/common";
import CircularProgressLong from "src/components/CircularProgressLong";
import VatechLinkLogo from "src/assets/svg/VatechLinkLogo";
import ChangePasswordPopup from "src/components/ChangePasswordPopup";
import {useIntl} from "react-intl";

const {Title} = Typography;

const SignIn = (props) => {
  const [form] = Form.useForm();
  const {firstLoginUser, isLoading, userLogin, userSNSLogin, setFirstLoginUser} = useAuth();
  const [SNSAccessToken, setSNSAccessToken] = useState(undefined);
  const intl = useIntl();

  useEffect(() => {
    const account = localStorage.getItem("rememberMe");

    if (account) {
      form.setFieldsValue({"rememberMe": true});
      form.setFieldsValue({"email": account});
    }

  }, []);

  useEffect(() => {
    if (SNSAccessToken) {
      userSNSLogin(getLoginFormData({accessToken: SNSAccessToken}));
    }
  }, [SNSAccessToken]);

  // const getLoginFormData = ({email, password, accessToken = null}) => {
  //
  //     const data = {
  //         grant_type: "password",
  //         client_id: '8b38cFmyzu3p1CQYAtfssBrUUMZdPmhPf4SnVjuE',
  //         client_secret: 'pbJcE7IXhU1i0nqeRcoLLtqj8irm9KA11wqSZ7LWpJlM0pkGmq08Ocqu5vw10Y26AeDzoKfwbXgrPBAfUlIQLoBTJ2T1hgUNwqtBXfZfK7XbeHio1a1UYX3bnupk8Sld',
  //     };
  //     const formData = new FormData();
  //     Object.entries(data).forEach(([key, value]) => {
  //         formData.append(key, value);
  //     });
  //
  //     if (accessToken) {
  //         formData.append("access_token", accessToken);
  //     } else {
  //         formData.append("username", email);
  //         formData.append("password", Buffer.from(password).toString('Base64'));
  //     }
  //
  //     return formData
  // };

  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    const {email, password, rememberMe} = values;

    if (rememberMe) {
      localStorage.setItem("rememberMe", email);
    } else {
      localStorage.removeItem("rememberMe");
    }


    // const data = {
    //   grant_type: "password",
    //   client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
    //   client_secret:process.env.NEXT_PUBLIC_CLIENT_SECRET,
    // };
    // const formData = new FormData();
    // Object.entries(data).forEach(([key, value]) => {
    //   formData.append(key, value);
    // });

    // formData.append("username", email);
    // formData.append("password", Buffer.from(password).toString('Base64'));

    console.log("onFinish", email, password)

    userLogin(getLoginFormData({email: email, password: password}));
  };

  if (SNSAccessToken) {
    return null
  }

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content" style={{justifyContent: 'center'}}>
            {/*<div className="gx-app-logo" style={{*/}
            {/*  display: 'flex',*/}
            {/*  justifyContent: 'center',*/}
            {/*}}>*/}
            {/*  <img alt="example" src="/assets/images/logo.png" height={72}/>*/}
            {/*</div>*/}
            <div className="gx-app-logo" style={{
              display: 'flex',
              justifyContent: 'center',
              transform: "scale(0.75)",
            }}>
              {/*<img alt="example" src="/assets/images/logo.png" height={40}/>*/}
              <VatechLinkLogo/>
            </div>
            <div className="gx-app-login-content" style={{
              padding: 0,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
            }}>
              <Title level={4} className="title-h4">
                <IntlMessages id="signin.adminSystem"/>
              </Title>
              {/*<Title level={1} className="title-h5 gx-mt-0 gx-mb-4">로그인 후 관리자 시스템 이용이 가능합니다.</Title>*/}
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              form={form}
              initialValues={{rememberMe: true}}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
              style={{marginBottom: 7}}
            >

              <Form.Item
                initialValue="test@test.com"
                rules={[{required: true, message: 'The input is not valid E-mail!'}]} name="email">
                <Input placeholder="Email"/>
              </Form.Item>
              <Form.Item
                initialValue=""
                rules={[{required: true, message: 'Please input your Password!'}]} name="password">
                <Input type="password" placeholder="Password"/>
              </Form.Item>
              <Row style={{display: 'flex'}}>
                <Col style={{flex: 1}}>
                  <Form.Item className={'remember-checked'} valuePropName="checked" name={"rememberMe"}
                             style={{marginBottom: 0}}>
                    <Checkbox>
                      <IntlMessages id="app.userAuth.rememberMe"/>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                  <Button type={'link'} href="/forgot-password" className="gx-login-form-forgot gx-link"
                          style={{fontSize: 14, color: '#000'}}>
                    <IntlMessages id="app.userAuth.forgotPassword"/>
                  </Button>

                </Col>
                {/*<a href="/signup" className="gx-login-form-forgot gx-link" style={{ marginLeft: 16 }}>*/}
                {/*    <IntlMessages id="app.userAuth.signUp" />*/}
                {/*</a>*/}

              </Row>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit"
                        style={{width: '100%', backgroundColor: '#db082f'}}>
                  <IntlMessages id="app.userAuth.signIn"/>
                </Button>
              </Form.Item>
              {/*<Row style={{borderBottom: '1px solid #5e5e5e'}}/>*/}
              {/* <span
                className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span> */}
            </Form>
          </div>
          {isLoading &&
            <CircularProgressLong messageId={'signin.inProgress'}/>
          }
          {firstLoginUser && <ChangePasswordPopup
            msg1={intl.formatMessage({id: `user.message.first_login`})}
            isFirst
            handleClose={() => {
              setFirstLoginUser(null)
            }}
          />}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
