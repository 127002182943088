import {START_PROGRESS, SUCCESS_PROGRESS, ERROR_PROGRESS} from "../../constants/ActionTypes";

export const startProgress = () => {
  return {
    type: START_PROGRESS
  }
};

export const successProgress = () => {
  return {
    type: SUCCESS_PROGRESS
  }
};

export const errorProgress = (error) => {
  return {
    type: ERROR_PROGRESS,
    payload: error
  }
};
