import {START_PROGRESS, SUCCESS_PROGRESS, ERROR_PROGRESS} from "../../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  count: 0,
  message: ''
};

const ProgressReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_PROGRESS: {
      return {...state, error: '', message: '', count: state.count + 1};
    }
    case SUCCESS_PROGRESS: {
      return {...state, error: '', message: '', count: Math.max(state.count - 1, 0)};
    }
    case ERROR_PROGRESS: {
      return {...state, error: action.payload, message: '', count:0};
    }
    default:
      return state;
  }
}

export default ProgressReducer;
