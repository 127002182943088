import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {Layout, Popover, Space, Tabs, theme} from "antd";

import {switchLanguage} from "../../appRedux/actions";
import {useAuth} from "../../authentication";
import {httpClient} from "../../util/Api";
import UserProfile from "./UserProfile";

import "./index.css";
import languageData from "./languageData";


const {Header} = Layout;

const Topbar = () => {
  const {gnb, setLnb} = useAuth();
  const {locale} = useSelector(({settings}) => settings);
  const [tabItems, setTabItems] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [isConfigOpen, setIsConfigOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const {
    token: {colorBgContainer},
  } = theme.useToken();

  const languageMenu = () => (
    <ul style={{listStyle: "none", padding: "0 10px", marginBottom: 0}}>
      {languageData.map((language) => (
        <li
          style={{cursor: "pointer", display: "flex"}}
          key={JSON.stringify(language)}
          onClick={() => dispatch(switchLanguage(language))}
        >
          <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} style={{marginRight: 10}}/>
          <span>{language.name}</span>
        </li>
      ))}
    </ul>
  );

  const onTabClick = (key, moveflag = true) => {

    // console.log('onTabclick getMenuLnb key', key)
    httpClient.post('common/get-menu-lnb', {gnb: key})
      .then((resp) => {
        const subMenu = resp.data.list[0].children ? resp.data.list[0].children[0].id : resp.data.list[0].id;
        if (moveflag)
          navigate('/' + key + '/' + subMenu);
        setLnb(resp.data);
        setActiveKey(key);

      })
      .catch((e) => {
      })
  };

  // useEffect(() => {
  //   if (gnb && gnb.list.length) {
  //     setTabItems(gnb.list.map((item) => {
  //       return {key: item.id, label: item.text}
  //     }));
  //   }
  // }, [gnb]);

  // useEffect(() => {
  //   if (location && location.pathname) {
  //     const gnbName = location.pathname.split('/')[1];

  //     if (gnbName !== activeKey) {
  //       onTabClick(gnbName, false);
  //     }
  //   }
  // }, [location])


  return (
    <Header style={{display: "flex", justifyContent: "space-between", background: colorBgContainer, padding: 0}}>
      <Tabs items={tabItems} tabBarStyle={{height: 64, fontSize: "14px"}}
            activeKey={activeKey}

            renderTabBar={(tabBarProps, DefaultTabBar) => (
              <DefaultTabBar {...tabBarProps}>
                {(node) => (
                  <div {...node.props} key={node.key} style={{width: 130, justifyContent: 'center'}}>{node}</div>
                )}
              </DefaultTabBar>
            )}
            onTabClick={onTabClick}
      />
      <div style={{paddingRight: 32, display: "flex", alignItems: "center"}}>
        <Space>
          {/* <Space style={{marginRight: 30}}>
              <AtomicButton type="text" icon={<SettingOutlined />} onClick={() => setIsConfigOpen(true)} />
              <AtomicButton type="text" icon={<FileSearchOutlined />} />
              <AtomicButton type="text" icon={<InfoCircleOutlined />} />
              <Popover
                placement="bottomRight"
                content={languageMenu()}
                trigger="click"
              >
                <span style={{cursor: "pointer", display: "flex", alignItems: "center", height: 36}}>
                  <i className={`flag flag-24 flag-${locale.icon}`} style={{marginRight: 10}}/>
                </span>
              </Popover>
            </Space> */}
          <Space style={{ display: 'flex', justifyContent: 'center'}}>
            <Popover
              placement="bottomRight"
              content={languageMenu()}
              trigger="click"
            >
                <span style={{cursor: "pointer", display: "flex", alignItems: "center", height: 36}}>
                  <i className={`flag flag-24 flag-${locale.icon}`} style={{marginRight: 10}}/>
                </span>
            </Popover>
            <UserProfile/>
          </Space>
        </Space>

      </div>
      {/* <ConfigPopup open={isConfigOpen} onCancel={() => setIsConfigOpen(false)}/> */}
    </Header>
  );
};

export default Topbar;
