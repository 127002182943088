import {INIT_URL, SWITCH_LANGUAGE, WINDOW_WIDTH} from "../../constants/ActionTypes";
import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_FIXED,
  THEME_COLOR,
  THEME_TYPE,
  THEME_TYPE_SEMI_DARK,
  UPDATE_RTL_STATUS
} from "../../constants/ThemeSetting";

const initialSettings = {
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  themeColor: '',
  width: 1367,
  isDirectionRTL: false,
  // locale: {
  //   languageId: 'english',
  //   locale: 'en',
  //   name: 'English',
  //   icon: 'us'
  // },
  locale: {
    languageId: 'korean',
    locale: 'kr',
    name: '한국어',
    icon: 'kr'
  },
  initURL: '',
};

const SettingsReducer = (state = initialSettings, action) => {
  switch (action.type) {

    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType
      };
    case THEME_COLOR:
      return {
        ...state,
        themeColor: action.themeColor
      };

    case UPDATE_RTL_STATUS:
      return {
        ...state,
        isDirectionRTL: action.rtlStatus
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle
      };
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType
      };

    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,

      };

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }

    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    default:
      return state;
  }
};

export default SettingsReducer;
