import React, {useEffect} from "react";
import {Layout, Modal} from "antd";
import {useDispatch} from "react-redux";

import {usePopup} from "util/use-popup";

import App from "../../routes/index";
import {updateWindowWidth} from "../../appRedux/actions";
import Topbar from "../Topbar/index";
import AppSidebar from "./AppSidebar";
import Progress from "../../components/Progress";

const {Content} = Layout;

const MainApp = () => {
  const dispatch = useDispatch();
  const {setModal} = usePopup();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (modal && setModal)
      setModal(modal);
  }, [modal, setModal]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth));
    })
  }, [dispatch]);

  return (
    <Layout style={{width: "100vw", height: "100vh",
      overflow: 'auto',
      // minWidth: 1200,
      // minHeight: 600
      }}>
      <AppSidebar/>
      <Layout style={{flex: 1, overflowY: 'hidden', overflowX: 'auto'}}>
        <Topbar/>
        <Content style={{
          display: "flex", flexDirection: "column", flexWrap: "nowrap", overflow: 'auto'}}>
          <Progress />
          <App/>
          {/* <App match={match}/> */}
          {/* <Footer>
            <div>
              {footerText}
            </div>
          </Footer> */}
        </Content>
      </Layout>
      {contextHolder}

    </Layout>
  )
};
export default MainApp;

