import React from "react";

const IconButton = ({className, color="#fff", onClick}) => {
  return <i 
            style={{width: 40, 
              height: 40, 
              borderRadius: 2, 
              display: "flex", 
              flexDirection: "row", 
              flexWrap: "wrap", 
              justifyContent: "center", 
              alignItems: "center", 
              cursor: "pointer",
              color
            }} 
            className={className} 
            onClick={onClick} />;
};

export default IconButton;
