import { combineReducers } from 'redux'
// import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Common from "./Common";
import Progress from "./Progress";

const createRootReducer = () => combineReducers({
  // router: connectRouter(history),
  settings: Settings,
  common: Common,
  progress: Progress,
});

export default createRootReducer;
