import { httpClient } from "../Api";

import { makeCreatorString, convertDateFormat } from "../common";

class BeApis {

  makeResult = (row) => {
    return {
      ...row,
      enforcement_date: convertDateFormat(row.enforcement_date),
      start_date: convertDateFormat(row.start_date),
      end_date: convertDateFormat(row.end_date),
      created_by_account: row.created_by?.name,
      created_at: convertDateFormat(row.created_at, 'withTime'),
      date_joined: convertDateFormat(row.created_at, 'withTime'),
      updated_by_account: row.updated_by?.name,
      updated_at: convertDateFormat(row.updated_at, 'withTime'),
      notified_by_account: row.notified_by?.name,
      notified_at: convertDateFormat(row.notified_at, 'withTime'),
      scheduled_at: row.notify_scheduled_date && convertDateFormat(`${row.notify_scheduled_date.split('T')[0]}T${row.notify_scheduled_at}`, 'withTime'),
      clinic_number: row.data?.clinic_number || 0,
      cs_number: row.data?.cs_number || 0,
      sub_user_number: row.data?.sub_user_number || 0,
      agent_number: row.data?.agent_number || 0,
      last_login: row.last_login && convertDateFormat(row.last_login, 'withTime'),
    }
  }
  makeSearchResultTable = (total, next, pageSize, data) => {
    let newData = []
    data.forEach((row, i) => {
      const rowResult = this.makeResult(row)
      const newRow = {
        ...row,
        ...rowResult,
        idx: total - ((next - 1)*pageSize + i),
      }
      newData.push(newRow)
    })
    return newData
  }

  async searchPost(type, req, next, pageSize) {
    try {
      let url = `v1/bo/${type}/search/?page_size=${pageSize}`;
      if (next) {
        url = `${url}&page=${next}`
      }
      const resp = await httpClient.post(url, req);

      const data = resp.data.results || [];
      resp.data.results = this.makeSearchResultTable(resp.data.count, next, pageSize, data)

      return Promise.resolve(resp.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getPostSearchOptions(type, option) {
    try {
      let url = `v1/bo/${type}/search-options/?field=${option}`;

      const resp = await httpClient.get(url);

      return Promise.resolve(resp.data || [])
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getSupportedLanguages() {
    try {
      let url = `v1/bo/supported-languages/`;

      const resp = await httpClient.get(url);

      return Promise.resolve(resp.data || [])
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getPost(type, id) {
    try {
      let url = `v1/bo/${type}/${id}`;

      const resp = await httpClient.get(url);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async createPost(type, values) {
    try {
      let url = `v1/bo/${type}/`;

      const resp = await httpClient.post(url, values);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async updatePost(type, id, values) {
    try {
      let url = `v1/bo/${type}/${id}`;

      const resp = await httpClient.patch(url, values);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async updatePostBulk(type, values) {
    try {
      let url = `v1/bo/update-${type}-bulk/`;

      const resp = await httpClient.post(url, values);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }


  async searchUser(type, req, next, pageSize) {
    try {
      let url = `v1/bo/users/${type}/search/?page_size=${pageSize}`;
      if (next) {
        url = `${url}&page=${next}`
      }
      const resp = await httpClient.post(url, req);

      const data = resp.data.results || [];
      resp.data.results = this.makeSearchResultTable(resp.data.count, next, pageSize, data)

      return Promise.resolve(resp.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getUserSearchOptions(type, option) {
    try {
      let url = `v1/bo/users/${type}/search-options/?field=${option}`;

      const resp = await httpClient.get(url);

      return Promise.resolve(resp.data || [])
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getUser(type, id) {
    try {
      let url = `v1/bo/users/${type}/${id}`;

      const resp = await httpClient.get(url);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async createUser(type, values) {
    try {
      let url = `v1/bo/users/${type}/`;

      const resp = await httpClient.post(url, values);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async updateUser(type, id, values) {
    try {
      let url = `v1/bo/users/${type}/${id}`;

      const resp = await httpClient.patch(url, values);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async updateUserBulk(type, values) {
    try {
      let url = `v1/bo/users/update-${type}-bulk/`;

      const resp = await httpClient.post(url, values);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }


  async searchSubsidiary(req, next, pageSize) {
    try {
      let url = `v1/bo/subsidiaries/search/?page_size=${pageSize}`;
      if (next) {
        url = `${url}&page=${next}`
      }
      const resp = await httpClient.post(url, req);

      const data = resp.data.results || [];
      resp.data.results = this.makeSearchResultTable(resp.data.count, next, pageSize, data)

      return Promise.resolve(resp.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getSubsidiarySearchOptions(option) {
    try {
      let url = `v1/bo/subsidiaries/search-options/?field=${option}`;

      const resp = await httpClient.get(url);

      return Promise.resolve(resp.data || [])
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getSubsidiary(id) {
    try {
      let url = `v1/bo/subsidiaries/${id}`;

      const resp = await httpClient.get(url);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async createSubsidiary(values) {
    try {
      let url = `v1/bo/subsidiaries/`;

      const resp = await httpClient.post(url, values);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async updateSubsidiary(id, values) {
    try {
      let url = `v1/bo/subsidiaries/${id}`;

      const resp = await httpClient.patch(url, values);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async updateSubsidiaryBulk(values) {
    try {
      let url = `v1/bo/update-subsidiaries-bulk/`;

      const resp = await httpClient.post(url, values);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }


  async searchWaiting(req, next, pageSize) {
    try {
      let url = `v1/bo/waiting/search/?page_size=${pageSize}`;
      if (next) {
        url = `${url}&page=${next}`
      }
      const resp = await httpClient.post(url, req);

      const data = resp.data.results || [];
      resp.data.results = this.makeSearchResultTable(resp.data.count, next, pageSize, data)

      return Promise.resolve(resp.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async updateWaitingBulk(values) {
    try {
      let url = `v1/bo/waiting/update-waiting-bulk/`;

      const resp = await httpClient.post(url, values);

      return Promise.resolve(this.makeResult(resp.data || {}))
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // async searchTerms(req, next, pageSize) {
  //   try {
  //     let url = `v1/bo/terms/search/?page_size=${pageSize}`;
  //     if (next) {
  //       url = `${url}&page=${next}`
  //     }
  //     const resp = await httpClient.post(url, req);
  //
  //     const data = resp.data.results || [];
  //     resp.data.results = this.makeSearchResultTable(resp.data.count, next, pageSize, data)
  //
  //     return Promise.resolve(resp.data)
  //   } catch (e) {
  //     return Promise.reject(e)
  //   }
  // }
  // async getTermsSearchOptions(option) {
  //   try {
  //     let url = `v1/bo/terms/search-options/?field=${option}`;
  //
  //     const resp = await httpClient.get(url);
  //
  //     return Promise.resolve(resp.data || [])
  //   } catch (e) {
  //     return Promise.reject(e)
  //   }
  // }
  // async getSupportedLanguages() {
  //   try {
  //     let url = `v1/bo/supported-languages/`;
  //
  //     const resp = await httpClient.get(url);
  //
  //     return Promise.resolve(resp.data || [])
  //   } catch (e) {
  //     return Promise.reject(e)
  //   }
  // }
  // async getTerms(id) {
  //   try {
  //     let url = `v1/bo/terms/${id}`;
  //
  //     const resp = await httpClient.get(url);
  //
  //     return Promise.resolve(this.makeResult(resp.data || {}))
  //   } catch (e) {
  //     return Promise.reject(e)
  //   }
  // }
  // async createTerms(values) {
  //   try {
  //     let url = `v1/bo/terms/`;
  //
  //     const resp = await httpClient.post(url, values);
  //
  //     return Promise.resolve(this.makeResult(resp.data || {}))
  //   } catch (e) {
  //     return Promise.reject(e)
  //   }
  // }
  // async updateTerms(id, values) {
  //   try {
  //     let url = `v1/bo/terms/${id}`;
  //
  //     const resp = await httpClient.patch(url, values);
  //
  //     return Promise.resolve(this.makeResult(resp.data || {}))
  //   } catch (e) {
  //     return Promise.reject(e)
  //   }
  // }
  // async updateTermsBulk(values) {
  //   try {
  //     let url = `v1/bo/update-terms-bulk/`;
  //
  //     const resp = await httpClient.post(url, values);
  //
  //     return Promise.resolve(this.makeResult(resp.data || {}))
  //   } catch (e) {
  //     return Promise.reject(e)
  //   }
  // }




  makeString = (d) => {
    let newString = d.new;
    let oldString = d.old;
    return oldString + " -> " + newString;

  }

  makeBooleanToString = (d) => {
    let newString = d.new ? "가능" : "불가능";
    let oldString = d.old ? "가능" : "불가능";
    return oldString + " -> " + newString;

  }

  makeArrayToString = (d) => {
    let newString = d.new.join(", ");
    let oldString = d.old.join(", ");
    return oldString + " -> " + newString;

  }

  makeObjectArrayToString = (d) => {
    let newString = d.new.map(e => e.name).join(", ");
    let oldString = d.old.map(e => e.name).join(", ");
    return oldString + " -> " + newString;

  }

  makeDateToString = (d) => {
    let newString = convertDateFormat(d.new);
    let oldString = convertDateFormat(d.old);
    return oldString + " -> " + newString;

  }

  async getSysinfo() {
    try {
      const resp = await httpClient.get('v1/bo/sysinfo/')
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getCICourses() {
    try {
      const resp = await httpClient.get('v1/ci/courses/')
      const data = resp.data || [];
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getCourses() {
    try {
      const resp = await httpClient.get('v1/cm/courses/')
      const data = resp.data || [];
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async searchCourses(requestValues) {
    try {
      const resp = await httpClient.post('v1/cm/courses/search/', requestValues)
      const data = resp.data || [];
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async createCourse(requestValues) {
    try {
      const resp = await httpClient.post('v1/cm/courses/', requestValues)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getTeachers() {
    try {
      const resp = await httpClient.get('v1/mm/teachers/')
      const data = resp.data || [];
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getMembers() {
    try {
      const resp = await httpClient.get('v1/mm/members/')
      const data = resp.data || [];
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getStudents() {
    try {
      const resp = await httpClient.get('v1/mm/members/students/')
      const data = resp.data || [];
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getCourse(id) {
    try {
      const resp = await httpClient.get(`v1/cm/courses/${id}`)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async updateCourse(id, requestValues) {
    try {
      const resp = await httpClient.patch(`v1/cm/courses/${id}`, requestValues)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async registerCourse(id, requestValues) {
    try {
      const resp = await httpClient.patch(`v1/ci/courses/${id}/register/`, requestValues)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async updateCourse(id, requestValues) {
    try {
      const resp = await httpClient.patch(`v1/cm/courses/${id}`, requestValues)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async dupCheck(params) {
    try {
      const resp = await httpClient.get(`v1/gw/exists/?${params}`)
      const data = resp.data;
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async SendEmailVerification(values) {
    try {
      const resp = await httpClient.post(`/v1/gw/send-email-verification/`, values)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async VerifyEmailCode(values) {
    try {
      const resp = await httpClient.post(`/v1/gw/verify-email-code/`, values)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async CreateSubUser(values) {
    try {
      const resp = await httpClient.post(`/v1/cs/sub-users/`, values)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async UpdateSubUser(id, values) {
    try {
      const resp = await httpClient.patch(`/v1/cs/sub-users/${id}`, values)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async SetPIN(values) {
    try {
      const resp = await httpClient.patch(`/v1/cs/pin/`, values)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async GetLoginHistories(values, page, pageSize) {
    try {
      let url = `/v1/gw/login-histories/?page_size=${pageSize}`
      if (page) {
        url = `${url}&page=${page}`
      }
      const resp = await httpClient.post(url, values)
      const data = resp.data || {};

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async RegisterFcmToken(req) {
    try {
      const resp = await httpClient.post(`/v1/gw/register-fcm-token/`, req)
      const data = resp.data || {};
      // const newData = this.makeNoticeTableData(data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}


const beApis = new BeApis();

export default beApis;
